@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./app/scss/common/variables";
@import "./app/scss/common/helpers";
@import "./app/scss/ui/card";
@import "./app/scss/plugins/tables";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html {
  background-color: #f1f2f7;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

ul li {
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

button,
input,
select,
a {
  outline: none !important;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.materialTableHeader {
  h2 {
    font-family: Poppins, sans-serif !important;
  }
}

ul.header-buttons {
  li {
    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

ul.header-buttons-left {
  padding-left: 0;
}


.input-form{
  width: 70%;
}


span.mat-ripple {
  display: none;
}

.report {
  margin: 0 auto;
  padding: 32px;
}

.container {
  max-width: 2220px;
}

.mat-mdc-card {
  margin-bottom: 32px;
}
.mat-mdc-table {
  background-color: white;
  border-spacing: 0;
  width: 100%;
  //diminuir tamanho dos botoes/icones para a linha ter um heigth compativel com o de 32px
  tr:nth-child(even) {
    background: #f4f5f7;
  }
  thead th,.mat-mdc-header-cell{
    font-weight: 600;
    padding: 0 16px;
    border-bottom: 1px solid rgba(0,0,0,.12);
    text-align: left;
  }
  thead tr, .mat-mdc-header-row  {
    height: 40px;
  }
  tbody tr .mat-mdc-row {
    height: 32px;
  }

  tbody tr td {
    color: rgba(0,0,0,.87);
  }
  td, .mat-mdc-cell, .mat-mdc-header-cell, .mat-mdc-footer-cell {
    font-size: 14px;
    padding: 0 16px;
    border-bottom: 1px solid rgba(0,0,0,.12);
  }

  tr:last-child {
    td {
      border-bottom: none;
    }
  }

  tfoot td, .mat-mdc-footer-cell {
    font-weight: 600;
  }
  tfoot tr, .mat-mdc-footer-row  {
    height: 32px;
  }
}

.small-icon-button {
  width: 32px !important;
  height: 32px !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  & > *[role=img] {
     width: 24px;
     height: 24px;
     font-size: 24px;

     svg {
        width: 24px;
        height: 24px;
     }
  }

  .mat-mdc-button-touch-target {
     width: 32px !important;
     height: 32px !important;
  }
}

.mat-mdc-tab-body-wrapper {
  padding: 24px 0;
}

.mat-mdc-form-field-error {
  display: inline !important;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}


$gridColumns: 12;
.grid {
  display: grid;
}
@for $i from 1 through $gridColumns {
  .grid-cols-#{$i} {
    grid-template-columns: repeat($i, 1fr);
  }
  .gap-#{$i} {
    gap: #{$i * 4}px;
  }
  .col-span-#{$i} {
    grid-column: span $i / span $i;
  }
  .grid-column-#{$i} {
    grid-column: $i;
  }
}

.w-full {
  width: 100%;
}