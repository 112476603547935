﻿.card {
  background: #fff;
  min-height: 50px;
  box-shadow: none;
  border: 0;
  position: relative;
  margin-bottom: 24px;
  border-radius: 10px;

  .header {
    color: #555;
    padding: 15px;
    position: relative;

    .header-dropdown {
      position: absolute;
      top: 10px;
      right: 10px;
      list-style: none;

      .dropdown-menu {
        li {
          display: block !important;
        }
      }

      li {
        display: inline-block;
      }

      i {
        font-size: 20px;
        color: #999;

        &:hover {
          color: #000;
        }
      }
    }

    h2 {
      margin: 0;
      font-size: 16px;
      font-weight: normal;
      color: #5b626b;

      small {
        display: block;
        font-size: 12px;
        margin-top: 5px;
        color: #999;
        line-height: 15px;

        a {
          font-weight: bold;
          color: #777;
        }
      }
    }

    .col-xs-12 {
      h2 {
        margin-top: 5px;
      }
    }
  }

  .body {
    font-size: 14px;
    color: #555;
    padding: 15px;

    @for $i from 1 through 12 {
      .col-xs-#{$i},
      .col-sm-#{$i},
      .col-md-#{$i},
      .col-lg-#{$i} {
        margin-bottom: 20px;
      }
    }
  }
}
