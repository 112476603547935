﻿/*
 *  Document   : _tables.scss
 *  Author     : RedStar Template
 *  Description: This scss file for tables style classes
 */

.table {
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  tr:nth-child(even) {
    background: #f4f5f7;
  }

  thead {
    th {
      padding:  0 8px;
    }
  }

  tbody {
    border-top: none !important;
    tr {
      td,
      th {
        padding: 12px 8px;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        vertical-align: middle;

        ul {
          margin: 0;
        }
      }
    }

    tr.primary {
      td,
      th {
        background-color: #1f91f3;
        color: #fff;
      }
    }

    tr.success {
      td,
      th {
        background-color: #2b982b;
        color: #fff;
      }
    }

    tr.info {
      td,
      th {
        background-color: #00b0e4;
        color: #fff;
      }
    }

    tr.warning {
      td,
      th {
        background-color: #ff9600;
        color: #fff;
      }
    }

    tr.danger {
      td,
      th {
        background-color: #fb483a;
        color: #fff;
      }
    }

    tr.row-highlight {
      background-color: #EEE;
      font-weight: bold;
    }
  }
}

.table-bordered {
  border-top: 1px solid #eee;

  tbody {
    tr {
      td,
      th {
        padding: 10px;
        border: 1px solid #eee;
      }
    }
  }

  thead {
    tr {
      th {
        padding: 10px;
        border: 1px solid #eee;
      }
    }
  }
}

tr.row-highlight {
  background-color: #EEE;
  font-weight: bold;
}

.btn-tbl-edit {
  background-color: #96a2b4;
  height: 30px !important;
  width: 30px !important;
  margin: 2px !important;
  line-height: 30px !important;
  color: #fff;

  // box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2) !important;
  .material-icons {
    font-size: 16px !important;
  }

  &:hover {
    background-color: #888;
    color: #fff;
  }

  &:focus {
    background-color: #888;
  }
}

.btn-tbl-delete {
  background-color: #ff944f;
  height: 30px !important;
  width: 30px !important;
  margin: 2px !important;
  line-height: 30px !important;
  color: #fff;

  // box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2) !important;
  .material-icons {
    font-size: 16px !important;
  }

  &:hover {
    background-color: #ff9600;
    color: #fff;
  }

  &:focus {
    background-color: #ff9600;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

tr.group,
tr.group:hover {
  background-color: #ddd !important;
}

.materialTableHeader {
  padding: 10px 20px;
  -webkit-box-align: center;
  align-items: center;
  border-color: #e6e9ed;
  background-color: #ebebee;
  height: 56px;
  min-width: 800px;
  position: relative;

  .header-buttons {
    position: absolute;
    right: 20px;
    padding: 6px 8px 8px 8px;

    li {
      display: inline-block;
    }
  }

  .header-buttons-left {
    position: absolute;
    list-style: none;
    padding-top: 3px;

    li {
      display: inline-block;
    }

    .searchbox {
      position: relative;
    }

    ::placeholder {
      color: rgba(0, 0, 0, 0.54);
    }

    .search-icon {
      position: absolute;
      top: 10px;
      padding-left: 10px;
      color: rgba(0, 0, 0, 0.54);
    }

    input.search-field {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.54);
      border-radius: 25px;
      border: 0;
      height: 45px;
      padding: 8px 8px 8px 50px;
      width: 250px;
      background: #ffffff;
      transition: background 0.2s, width 0.2s;

      &:hover {
        background: #fffefe;
      }

      // &:focus {
      //   outline: none;
      //   width: 380px;
      //   color: #212121;
      //   &:hover {
      //     background: #f7f7f7;
      //   }
      // }
    }
  }

  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: normal;
    color: #5b626b;
  }
}
